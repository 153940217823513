import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import Button from '../Button/Button.component';
import { StoryblokAsset } from '../../interfaces/Asset.interface';
import { StoryblokLink } from '../../interfaces/StoryblokLink.interface';
import RichText from '../RichText/RichText.component';
import classnames from 'classnames';
import { Blok } from '../../interfaces/Blok.interface';
import { Gym } from '../../interfaces/GymData';
import { SortAlphabeticallyByName } from '../../utils/sortAlphabeticallyByName';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import SuccessIcon from '../../svg/success.svg';
import ErrorIcon from '../../svg/error.svg';
import ReCAPTCHA from 'react-google-recaptcha';
import TagManager from 'react-gtm-module';

interface CTA extends Blok {
  label: string;
  link: StoryblokLink;
}

interface FiftyFiftyRegistrationProps {
  heading: string;
  subheading: string;
  body: any;
  cta: CTA;
  image: StoryblokAsset;
  reverse: boolean;

  // Form fields
  formHeading: string;
  formBody: any;
  showForm: boolean;
  gyms: Gym[];
  formCta: CTA;
}

const FiftyFiftyRegistration: React.FC<FiftyFiftyRegistrationProps> = ({
  heading,
  subheading,
  body,
  cta,
  image,
  reverse,
  formHeading,
  formBody,
  showForm,
  gyms,
  formCta,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formError, setFormError] = useState(false);
  const captchaRef = useRef(null);
  const formLabelClasses = 'uppercase font-bold text-sm pb-2';
  const formInputClasses =
    'w-full pl-2 h-12 mb-4 text-body/body focus:ring-0 focus:outline-none';

  useEffect(() => {
    if (formSubmitted) {
      setTimeout(() => {
        setFormSubmitted(false);
        setFormError(false);
      }, 5000);
    }
  }, [formError, formSubmitted]);

  const onSubmitHandler = async (data, event) => {
    event.preventDefault();

    const captchaToken = captchaRef.current.getValue();

    if (!captchaToken) {
      alert('Please complete the captcha');
      return;
    }

    const {
      firstName = '',
      lastName = '',
      email = '',
      mobile = '',
      gym,
    } = data;

    const perfectGymClubId = gyms.find(
      (club) => club.site_id === gym
    )?.pg_club_id;

    if (typeof window !== 'undefined') {
      TagManager.initialize({
        gtmId: process.env.GATSBY_GTM_CONTAINER_ID,
        dataLayer: {
          event: 'sign_up',
          gym_location: gym,
          signup_page: window.location.href,
        },
      });
    }

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    captchaRef.current.reset();

    const url = perfectGymClubId
      ? `${process.env.GATSBY_EVERLAST_API_URL}/register/v2`
      : `${process.env.GATSBY_EVERLAST_API_URL}/register`;

    const additionalPayload = perfectGymClubId
      ? {
          clubId: perfectGymClubId,
          phone: mobile,
        }
      : {
          gym,
          mobile,
        };

    try {
      await axios.post(
        url,
        { firstName, lastName, email, captchaToken, ...additionalPayload },
        {
          headers,
        }
      );

      setFormError(false);
      setFormSubmitted(true);
      reset({
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        gym: '',
      });
    } catch (error) {
      setFormError(true);
      setFormSubmitted(true);
    }
  };

  return (
    <section>
      <div className="bg-texture-secondary bg-repeat bg-200 bg-texture/secondary ">
        <div className="text-white grid grid-cols-1 lg:grid-cols-2">
          <div
            className={classnames(
              'lg:flex lg:items-center lg:justify-center order-2 sm:max-w-screen-sm md:max-w-screen-md lg:max-w-full mx-auto',
              {
                'lg:order-1': reverse,
                'lg:order-2': !reverse,
              }
            )}
          >
            <div className="lg:w-108 px-4 py-10 lg:py-20">
              <div className="flex items-center">
                <div>
                  <div className="flex">
                    {formHeading && (
                      <h3 className="text-3xl sm:text-3xl font-oskari-g2--bold antialiased pt-3 uppercase leading-10">
                        {formHeading}
                      </h3>
                    )}
                  </div>

                  {formBody && (
                    <RichText
                      textSize="text-xl pt-7 pb-2"
                      document={formBody}
                    />
                  )}

                  {showForm && (
                    <>
                      <form onSubmit={handleSubmit(onSubmitHandler)}>
                        <div className="grid">
                          {formSubmitted && (
                            <div
                              className={classnames(
                                'w-full py-3 px-3 my-4 flex gap-2 order-last md:order-first',
                                {
                                  'bg-primary/500': !formError,
                                  'bg-error': formError,
                                }
                              )}
                            >
                              {formError && (
                                <>
                                  <ErrorIcon />
                                  <p className="text-base font-oskari-g2--semibold antialiased uppercase">
                                    Something went wrong! Please try again or
                                    speak to your nearest gym.
                                  </p>
                                </>
                              )}

                              {!formError && (
                                <>
                                  <SuccessIcon />
                                  <p className="text-base font-oskari-g2--semibold antialiased uppercase">
                                    Registration Success
                                  </p>
                                </>
                              )}
                            </div>
                          )}

                          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4">
                            <div>
                              <label
                                htmlFor="firstName"
                                className={formLabelClasses}
                              >
                                First Name
                              </label>
                              <input
                                {...register('firstName', { required: true })}
                                placeholder="First name"
                                type="text"
                                required
                                className={formInputClasses}
                              />
                            </div>
                            <div>
                              <label
                                htmlFor="lastName"
                                className={formLabelClasses}
                              >
                                Last Name
                              </label>
                              <input
                                {...register('lastName', { required: true })}
                                placeholder="Last name"
                                type="text"
                                required
                                className={formInputClasses}
                              />
                            </div>
                          </div>

                          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4">
                            <div>
                              <label
                                htmlFor="email"
                                className={formLabelClasses}
                              >
                                Email
                              </label>
                              <input
                                {...register('email', { required: true })}
                                placeholder="Email"
                                type="email"
                                required
                                className={formInputClasses}
                              />
                            </div>

                            <div>
                              <label
                                htmlFor="mobile"
                                className={formLabelClasses}
                              >
                                Mobile
                              </label>
                              <input
                                {...register('mobile', { required: true })}
                                placeholder="Mobile"
                                type="tel"
                                required
                                className={formInputClasses}
                              />
                            </div>
                          </div>

                          <label
                            htmlFor="select-gym"
                            className={formLabelClasses}
                          >
                            Select your gym
                          </label>

                          <select
                            {...register('gym', { required: true })}
                            className={formInputClasses}
                          >
                            <option value="">Please select a gym...</option>
                            {gyms
                              .sort(SortAlphabeticallyByName)
                              .map((gym, index) => (
                                <option
                                  key={gym.uid + index}
                                  value={gym.site_id}
                                >
                                  {gym.name}
                                </option>
                              ))}
                          </select>

                          {/* errors will return when field validation fails  */}
                          {errors.gym && <span>Please select a gym</span>}

                          <div className="flex justify-end">
                            <ReCAPTCHA
                              className="w-full"
                              sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
                              ref={captchaRef}
                            />
                          </div>

                          <div className="flex justify-start">
                            <button
                              disabled={isSubmitting}
                              type="submit"
                              className="w-full sm:w-auto transition-all hover:bg-primary cursor-pointer pt-4 px-8 pb-3 uppercase text-xl font-oskari-g2--semibold antialiased bg-white text-center text-black mt-4"
                            >
                              Register
                            </button>
                          </div>
                        </div>
                      </form>
                    </>
                  )}

                  {/* If there's a Form CTA AND you're not showing the form already */}
                  {formCta && !showForm && (
                    <div className="flex justify-start">
                      <Button
                        cta_label={formCta.label}
                        cta_link={formCta.link}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className={classnames(
              'lg:flex lg:items-center lg:justify-center order-1 ',
              {
                'lg:order-2': reverse,
                'lg:order-1': !reverse,
              }
            )}
            style={{
              backgroundImage: `url(${image.filename})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <div className="lg:w-108 px-4 py-20 lg:py-6 sm:max-w-screen-sm md:max-w-screen-md lg:max-w-full mx-auto">
              <div className="flex items-center">
                <div>
                  <div className="flex">
                    {heading && (
                      <h3 className="text-3xl sm:text-5xl font-oskari-g2--bold antialiased pt-3 uppercase leading-10">
                        {heading}
                      </h3>
                    )}
                  </div>
                  {subheading && (
                    <p className="text-2xl font-oskari-g2--semibold antialiased pt-7 pb-2 uppercase">
                      {subheading}
                    </p>
                  )}
                  {body && <RichText textSize="text-xl" document={body} />}
                  {cta && (
                    <div className="flex justify-start">
                      <Button cta_label={cta.label} cta_link={cta.link} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FiftyFiftyRegistration.defaultProps = {
  heading: '',
  subheading: '',
  body: null,
  cta: null,
  image: null,
  reverse: false,
};

FiftyFiftyRegistration.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string,
  body: PropTypes.any,
  cta: PropTypes.any,
  image: PropTypes.any,
  reverse: PropTypes.bool,
};

export default FiftyFiftyRegistration;
